<template>
<div>
  <v-container class="mycontainer">
         <div class="page-header">
              <h4 class="page-title">{{breadcrumb.meta.title}}</h4>
              <ul class="breadcrumbs">
                <li class="nav-home">
                  <a href="#">
                    <i class="flaticon-home"></i>
                  </a>
                </li>
                <li class="separator" v-if="breadcrumb.meta.step">
                  <i class="flaticon-right-arrow"></i>
                </li>
                <li class="nav-item" v-if="breadcrumb.meta.step">
                  <a @click="goBack()">{{back}}</a>
                </li>
                <li class="separator">
                  <i class="flaticon-right-arrow"></i>
                </li>
                <li class="nav-item">
                  <a href="#">{{breadcrumb.meta.path_name}}</a>
                </li>
              </ul>
            </div>
  </v-container>
</div>
   
</template>
<script>
export default {
    data(){
      return{
        back:"Previous Page"
      }
    },
    computed:{
        breadcrumb(){
          let r = this.$route;
          return r;
        }
    },
    methods:{
      goBack(){
        // return this.$router.go(-1);
          return this.$router.back();
      },
      goLink(b){
        return this.$router.push({name:b.name});
      }
    },
    created(){
        console.log("form:", this.breadcrumb)
      // this.Bus.$on("breadcrumb-back-name",(t)=>{
      //   this.back = t;
      // })
    }
}
</script>
<style scoped>
  .page-header{
    display: flex;
    align-items: center;
    margin-bottom: 2px;
  }
   .page-header .page-title {
    margin-bottom: 0;
}
</style>