<template>
<div>
    <v-container class="mycontainer">
            <v-card>
         <my-loader></my-loader>
        <v-card-title>
            All Assigned Applications
            <v-spacer></v-spacer>
            <v-text-field
            v-model="search"
          
            label="Search"
            single-line
            hide-details
        ></v-text-field>
        </v-card-title>
        <v-card-text>
             <div class="m-t-25 ">
                     <v-data-table
                        :headers="headers"
                        :items="mortgages"
                        :items-per-page="5"
                        :search=search
                        class="elevation-1"
                    >
                            <template v-slot:item.mortgage_status_name="{ item }">
                                <v-chip small class="ma-2 text-white" :color="showMortgageStatus(item.mortgage_status_name)">{{item.mortgage_status_name}}</v-chip>
                                    
                            </template>
                            <template v-slot:item.firstname="{ item }">
                                    {{item.firstname}} {{item.lastname}}
                            </template>
                             <template v-slot:item.loan_amount="{ item }">
                                    {{item.loan_amount| currency}}
                            </template>
                             <template v-slot:item.total_annual_pay="{ item }">
                                    {{item.total_annual_pay| currency}}
                            </template>
                              <template v-slot:item.loan_tenure="{ item }">
                                    {{item.loan_tenure}} years
                            </template>
                              <template v-slot:item.action="{ item }">
                               

                                    <!-- <v-btn color="primary" dark>{{item.id}}</v-btn> -->
                            <v-icon  medium class="mr-2" @click="showDetailPage(item.slug)">mdi-eye-circle-outline
                            </v-icon>
                            </template>
                    </v-data-table>
                     </div>
        </v-card-text>
    </v-card>
    </v-container>
</div>
    
</template>

<script>
import {loadingMixin} from '../../../mixins/Loading'
import {mortgageMixin} from '../../../mixins/mortgage'
    export default {
        name:"Nhf",
        mixins:[loadingMixin,mortgageMixin],
        data(){
            return{
                search:'',
                headers:[
                { text: 'Status', value: 'mortgage_status_name' },
                { text: 'Date', value: 'created_at' },
                   { text: 'Type', value: 'type' },
                {text: 'Ref No',align: 'left',sortable: false,value: 'app_ref' },
                { text: 'Customer', value: 'firstname' },
                { text: 'Loan Amount', value: 'loan_amount' },
                { text: 'Annual Income', value: 'total_annual_pay' },
                { text: 'Loan Tenure', value: 'loan_tenure' },
              
                {text:'Action',value: 'action', sortable: false }
                ]
            }
        },
        computed: {
            mortgages(){
                let p = this.$store.state.regular_mortgage_module.assigned_mortgage_application
                return p
            }
        },
        methods: {
            allAssinedMortgage(){
                this.$store.dispatch("allAssinedMortgageAction");
            },
            showMortgageStatus(st){
                switch (st) {
                    case 'Pending':
                        return 'red'
                        break;
                    case 'Approved':
                    return 'green'
                    break;
                    case 'Verifying':
                    return 'blue'
                    break;
                
                    default:
                        break;
                }
            }
        },
        created() {
            this.allAssinedMortgage();
        },
    }
</script>
