<template>
    <div>
        <v-container class="mycontainer">
           
            <div class="row">
						<div class="col-sm-6 col-md-3">
							<div class="card card-stats card-round">
								<div class="card-body ">
									<div class="row align-items-center">
										<div class="col-icon">
											<div class="icon-big text-center icon-primary bubble-shadow-small">
												<i class="flaticon-users"></i>
											</div>
										</div>
										<div class="col col-stats ml-3 ml-sm-0">
											<div class="numbers">
												<p class="card-category">Total Applications</p>
												<h4 class="card-title">{{dashboard.total_received}}</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-6 col-md-3">
							<div class="card card-stats card-round">
								<div class="card-body">
									<div class="row align-items-center">
										<div class="col-icon">
											<div class="icon-big text-center icon-info bubble-shadow-small">
												<i class="flaticon-interface-6"></i>
											</div>
										</div>
										<div class="col col-stats ml-3 ml-sm-0">
											<div class="numbers">
												<p class="card-category">Total Pending</p>
												<h4 class="card-title">{{dashboard.total_pending}}</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-6 col-md-3">
							<div class="card card-stats card-round">
								<div class="card-body">
									<div class="row align-items-center">
										<div class="col-icon">
											<div class="icon-big text-center icon-success bubble-shadow-small">
												<i class="flaticon-graph"></i>
											</div>
										</div>
										<div class="col col-stats ml-3 ml-sm-0">
											<div class="numbers">
												<p class="card-category">Total Verified
												<h4 class="card-title">{{dashboard.total_approved}}</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-6 col-md-3">
							<div class="card card-stats card-round">
								<div class="card-body">
									<div class="row align-items-center">
										<div class="col-icon">
											<div class="icon-big text-center icon-secondary bubble-shadow-small">
												<i class="flaticon-success"></i>
											</div>
										</div>
										<div class="col col-stats ml-3 ml-sm-0">
											<div class="numbers">
												<p class="card-category">Total Approved</p>
												<h4 class="card-title">{{dashboard.total_approved}}</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
            </div>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card style="padding:10px">
                        <my-loader></my-loader>
                        <v-card-title>
                            <h3>Approved Loans</h3>
                            <v-spacer></v-spacer>
                            <v-btn icon  outlined fab>
                                <v-icon size="" color="primary" class="">mdi-file-multiple-outline</v-icon>
                            </v-btn>

                        </v-card-title>

                        <v-card-subtitle>
                            <h3>{{dashboard.total_approved_value|currency}}</h3>
                            <!-- <h1>{{dashboard.total_received}}</h1> -->
                        </v-card-subtitle>

                        <!-- <v-card-actions> -->
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-center">Total Customers</th>
                                    <th class="text-center">Total Transactions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr >
                                    <td class="text-center t_font">{{dashboard.total_approved_customer}}</td>
                                    <td class="text-center t_font">{{dashboard.total_approved}}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>





                        <!-- </v-card-actions> -->
                    </v-card>
                </v-col>
                <v-col sm="6" md="6" xs="12">
                    <v-card style="padding:10px">
                        <my-loader></my-loader>
                        <v-card-title>
                            <h3>Pending Loans</h3>
                            <v-spacer></v-spacer>
                            <v-btn icon  outlined fab>
                                <v-icon  color="green" class="">mdi-cash-multiple</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-card-subtitle>
                            <h3>{{dashboard.total_pending_value|currency}}</h3>
                        </v-card-subtitle>

                        <!-- <v-card-actions> -->
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-center">Pending Customers</th>
                                    <th class="text-center">Pending Transactions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr >
                                    <td class="text-center t_font">{{dashboard.total_pending_customer}}</td>
                                    <td class="text-center t_font">{{dashboard.total_received}}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>





                        <!-- </v-card-actions> -->
                    </v-card>
                </v-col>


            </v-row>
            <!-- <div class="row">
                <div class="col-sm-6 col-md-6">
                    <v-card>
                        <v-card-text>
                              <bar-chart :height="250" :chartData="info" title="Regular Mortgage"></bar-chart>
                        </v-card-text>
                        
                    </v-card>
                </div>
                    <div class="col-sm-6 col-md-6">
                    <v-card>
                        <v-card-text>
                              <bar-chart :height="250" :chartData="info" title="NHF Mortgage"></bar-chart>
                        </v-card-text>
                        
                    </v-card>
                </div>
            </div> -->

                <v-card>
                       <v-card-title>
                    <h5>Recent Applications</h5>
                    <v-spacer></v-spacer>
                </v-card-title>

                   <v-data-table :loading="apiloading" loading-text="Loading... Please wait"
                        :headers="mortgage_headers"
                        :items="mortgages"
                        :items-per-page="10"
                        :search=search
                        class="elevation-1"
                         :single-expand="singleExpand"
                         show-expand
                    >

                            <template v-slot:item.firstname="{ item }">
                                    {{item.firstname}} {{item.lastname}}
                            </template>
                             <template v-slot:item.loan_amount="{ item }">
                                    {{item.loan_amount| currency}}
                            </template>
                              <template v-slot:item.loan_tenure="{ item }">
                                    {{item.loan_tenure}}
                            </template>
                              <template v-slot:item.action="{ item }">
                            <v-icon  medium class="mr-2" @click="showSummary(item.slug)">mdi-eye-circle-outline
                            </v-icon>
                            </template>

                    </v-data-table>
                </v-card>
             
       
        </v-container>
    </div>
</template>
<script>
import { loadingMixin} from '../../mixins/Loading'
import {mortgageMixin} from '../../mixins/mortgage'
export default {
    mixins:[mortgageMixin,loadingMixin],
    data() {
        return {
            search:'',
            singleExpand: true,
               mortgage_headers:[
                { text: 'Date', value: 'created_at' },
                  { text: 'Type', value: 'type' },
                {text: 'Ref No',align: 'left',sortable: false,value: 'app_ref' },
                { text: 'Customer', value: 'firstname' },
                { text: 'Loan Amount', value: 'loan_amount' },
                { text: 'Tenure(Y)', value: 'loan_tenure' },
                 {text:'Action',value: 'action', sortable: false },
               // { text: 'Action', value: 'data-table-expand', sortable: false },
                ],
                 info:{
                    total_pendiing:20,
                    total_approved:30,
                    total_query:5,
                    total_verified:7
                },
            

        }
    },
    computed: {
        dashboard(){
            return this.$store.state.mortgage_module.dashboard;
        },
        mortgages(){
            if(this.user.is_admin !==1){    
                    return  this.$store.state.mortgage_module.staff_recent_mortgage;
                }else{
                    return  this.$store.state.mortgage_module.recent_mortgage;
                }
        },
         user(){
              return this.$store.state.authmodule.user;
            },
    },
    methods: {
         showMortgageStatus(st){
                switch (st) {
                    case 'Pending':
                        return 'red'
                        break;
                    case 'Approved':
                    return 'green'
                    break;
                    case 'Verifying':
                    return 'blue'
                    break;
                
                    default:
                        break;
                }
        },
            allMortgage(){
                if(this.user.is_admin !==1){
                    this.$store.dispatch("allStaffRecentMortgage",this.user.id)
                }else{
                 this.$store.dispatch("allRecentMortgage")
                }
                
            },
            loadDashboardInfo(){
                this.$store.dispatch("dashboardInfoAction");
            }
    },
    created() {
        this.loadDashboardInfo();
        this.allMortgage();
        this.$store.dispatch("allPendingMortgageAction")
    },
}
</script>
<style  scoped>
/*    .t_font{*/
/*        font-size:20px*/
/*    }*/
/*    table{*/
/*    table-layout: inherit !important;*/
/*      border-collapse: seperate;*/
/*    border-spacing: 0 15px !important;*/
/*}*/
/*.mytable{*/
/*    border-collapse: seperate;*/
/*    border-spacing: 0 15px !important;*/
/*}*/
/*tr{*/
/*background: #fff;*/
/*box-shadow: 10px;*/
/*}*/
/*.table thead th {*/
/*font-weight: bold;*/
/*}*/
/*.set-height td{*/
/*position: relative;*/
/*overflow: hidden;*/
/*height: 2em;*/
/*}*/
.card{
    margin-bottom: 0px !important;
}
</style>