
// import EventBus from '../services/event'
export const mortgageMixin ={
    methods: {
        // showDetailPage(slug){
        //     // EventBus.$emit("show",false)
        //     //  EventBus.$emit('show_regular',true);
        //     this.$store.dispatch("mortgageDetailAction",slug);
        //     // this.mortgagedetails = item;
        //     this.$router.push({name:'mortgage_detail',params:{slug:slug}});
        // },
        //  showListPage(){
        //      EventBus.$emit('show_regular',false);
        //     this.mortgagedetails = '';
        // },
        showSummary(slug){
            console.log("from mixin",slug)
            this.$router.push({name:'mortgage_summary',params:{slug:slug}});
        },
        goToDetailPage(slug){
            this.$router.push({name:'mortgage_detail',params:{slug:slug}});
        }
       
    },


   
 }