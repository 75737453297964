<template>
    <div>
        <v-container class="mycontainer">
         <v-card id="printMe2">
             
       <my-loader></my-loader>
       <v-card-title>
           <v-btn icon @click="goBack()">
               <v-icon size="40">mdi-arrow-left-bold</v-icon>
           </v-btn>
             Details

            
           <v-spacer></v-spacer>
           <v-btn  dark medium color="green"  @click="print()" ><v-icon  medium class="mr-2">mdi-cloud-print-outline </v-icon>Print</v-btn>
           
              <v-menu
                    bottom
                    origin="center center"
                    transition="scale-transition"
                    >
                    <template v-slot:activator="{ on }">
                        <v-btn
                        color="primary"
                        dark
                        v-on="on"
                        >
                        Actions
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item
                        v-for="(item, i) in action_dropdown"
                        :key="i"
                        @click="dropdownaction(item.action)"
                        >
                        <v-list-item-title v-if="details.mortgage_status_name != item.text">{{ item.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    </v-menu>
       </v-card-title>
         <v-chip class="ma-2 ml-5" color="secondary"  >{{details.mortgage_status_name}}</v-chip>
       <div id="printMe">
           
             <v-row justify="center" class="print_image">
                 <div v-if="details.lender" :style="{backgroundImage:'url('+details.lender.lender_logo+')'}" class="lender_logo2"></div>
             </v-row>
              
            <v-card-text>
                <!-- <div class="lender_logo" :style="{backgroundImgae:'url('+details+')'}"></div> -->
                <!-- <div class="display-flex"> -->
                    <v-card-title>
                        <h3 class="text-bold">REF:  {{details.app_ref}} </h3>
                        <v-spacer></v-spacer>
                       
                           <v-spacer></v-spacer>
                         <h3>DATE: {{details.created_at | formatDate}}</h3>
                    </v-card-title>
                <!-- </div> -->
                <v-row>
                    <v-col cols="12">
                            <div class='componentWraper'>
                                <p class='componentTitle'>Personal Info</p>
                                <v-row>
                                    <v-col md="3">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">First Name</v-list-item-title>
                                                <v-list-item-subtitle>{{details.firstname }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Last Name</v-list-item-title>
                                                <v-list-item-subtitle>{{details.lastname}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Middle Name</v-list-item-title>
                                                <v-list-item-subtitle>{{details.middlename }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Email</v-list-item-title>
                                                <v-list-item-subtitle>{{details.email}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Phone</v-list-item-title>
                                                <v-list-item-subtitle>{{details.phone}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Date of Birth</v-list-item-title>
                                                <v-list-item-subtitle>{{details.dob}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Sex</v-list-item-title>
                                                <v-list-item-subtitle>{{details.sex }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Place of Birth</v-list-item-title>
                                                <v-list-item-subtitle>{{details.place_of_birth }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3" >
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Nationality</v-list-item-title>
                                                <v-list-item-subtitle>{{details.nationality }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">State of Origin</v-list-item-title>
                                                <v-list-item-subtitle>{{details.state_of_origin}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Highest Education</v-list-item-title>
                                                <v-list-item-subtitle>{{details.highest_education}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Profession</v-list-item-title>
                                                <v-list-item-subtitle>{{details.profession}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="6">
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Address</v-list-item-title>
                                                <v-list-item-subtitle>{{details.address}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="4" v-if="details.type=='nhf'">
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">NHF Registration Number</v-list-item-title>
                                                <v-list-item-subtitle>{{details.nhf_registration_number}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                </v-row>
                            </div>
                    </v-col>
                        <v-col cols="12">
                            <div class='componentWraper'>
                                <p class='componentTitle'>Other Personal Info</p>
                                <v-row>
                                    <v-col md="3" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Home Type</v-list-item-title>
                                                <v-list-item-subtitle>{{checkNull(details.current_apartment_status)}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Annual Rent</v-list-item-title>
                                                <v-list-item-subtitle>{{details.annual_rent_value|currency}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Marital Status</v-list-item-title>
                                                <v-list-item-subtitle>{{details.marital_status  }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3" >                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Spouse's Name</v-list-item-title>
                                                <v-list-item-subtitle>{{checkNull(details.spouse_name)}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Spouse's Employment Status</v-list-item-title>
                                                <v-list-item-subtitle>{{checkNull(details.spouse_employment_status)}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Spouse's Employer</v-list-item-title>
                                                <v-list-item-subtitle>{{checkNull(details.spouse_employer)}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">No of Years Employed</v-list-item-title>
                                                <v-list-item-subtitle>{{checkNull(details.spouse_work_experience)}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Spouse's Profession</v-list-item-title>
                                                <v-list-item-subtitle>{{checkNull(details.spouse_profession)}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3" >
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Spouse's Annual Income</v-list-item-title>
                                                <v-list-item-subtitle>{{checkNull(details.spouse_annual_income) | currency}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Next of Kin</v-list-item-title>
                                                <v-list-item-subtitle>{{checkNull(details.next_of_kin_name)}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3" >
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Next of Kin Relationship</v-list-item-title>
                                                <v-list-item-subtitle>{{checkNull(details.next_of_kin_relationship)}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3" >
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Next of Kin's Age</v-list-item-title>
                                                <v-list-item-subtitle>{{checkNull(details.next_of_kin_age)}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="6" >
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Next of Kin Address</v-list-item-title>
                                                <v-list-item-subtitle>{{checkNull(details.next_of_kin_address)}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-list-item-title class="text-bold">Children / Other Dependents</v-list-item-title>
                                        <v-simple-table class="table-bordered table-striped">
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                <th class="text-left">Name</th>
                                                <th class="text-left">Age</th>
                                                <th class="text-left">Relationship</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    <tr v-for="(ch, i) in JSON.parse(details.children)" :key="i">
                                                        <td>{{ch.name}}</td>
                                                        <td>{{ch.age}}</td>
                                                        <td>{{ch.relationship}}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                        

                                    </v-col>
                                </v-row>
                            </div>
                    </v-col>
                    <v-col cols="12">
                            <div class='componentWraper'>
                                <p class='componentTitle'>Employment Information</p>
                                <v-row>
                                    <v-col md="4" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Company Name</v-list-item-title>
                                                <v-list-item-subtitle>{{checkNull(details.employer_name)}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="4" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Company Email</v-list-item-title>
                                                <v-list-item-subtitle>{{details.employer_email }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="4" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Company Phone</v-list-item-title>
                                                <v-list-item-subtitle>{{details.employer_phone  }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col  md="4">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Company Address</v-list-item-title>
                                                <v-list-item-subtitle>{{details.employer_address}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                      <v-col md="4" v-if="details.type=='nhf'">
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Employer NHF Registration Number</v-list-item-title>
                                                <v-list-item-subtitle>{{details.employer_nhf_registration_number}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                      <v-col md="4" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Employer Guarantee</v-list-item-title>
                                                <v-list-item-subtitle>{{details.have_employer_guarantor}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                
                                </v-row>
                            </div>
                    </v-col>
                    <v-col cols="12">
                            <div class='componentWraper'>
                                <p class='componentTitle'>Loan & Financial Information</p>
                                <v-row>
                                    <v-col md="3" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Total Annual Pay</v-list-item-title>
                                                <v-list-item-subtitle>{{details.total_annual_pay| currency}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Monthly Net Income</v-list-item-title>
                                                <v-list-item-subtitle>{{details.monthly_net_pay | currency}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Monthly Gross Income</v-list-item-title>
                                                <v-list-item-subtitle>{{details.monthly_gross_pay |currency }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Monthly Expenses</v-list-item-title>
                                                <v-list-item-subtitle>{{details.monthly_expenses | currency}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                     <v-col md="3">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Loan Amount</v-list-item-title>
                                                <v-list-item-subtitle>{{details.loan_amount| currency}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Equity Contribution</v-list-item-title>
                                                <v-list-item-subtitle>{{details.down_payment | currency}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                    <v-col md="3">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Loan Tenure</v-list-item-title>
                                                <v-list-item-subtitle>{{details.loan_tenure }} Years</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                      <v-col md="3" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Property Value</v-list-item-title>
                                                <v-list-item-subtitle>{{details.property_value}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                     <v-col md="3" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Property Title</v-list-item-title>
                                                <v-list-item-subtitle>{{details.property_title}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>
                                       <v-col md="3" >
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-bold">Property Address</v-list-item-title>
                                                <v-list-item-subtitle>{{details.property_address}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            </v-list-item>
                                    </v-col>


                                  
                                    <v-col cols="12">
                                        <v-list-item-title class="text-bold">Other Source of Income</v-list-item-title>
                                            <v-simple-table :fixed-header=true class="table-bordered table-striped">
                                                <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                    <th class="text-left">Source</th>
                                                    <th class="text-left">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(ch, i) in JSON.parse(details.other_source_of_income)" :key="i">
                                                        <td>{{ch.source}}</td>
                                                        <td>{{ch.amount | currency}}</td>
                                                    </tr>
                                                </tbody>
                                                </template>
                                            </v-simple-table>
                                        

                                    </v-col>
                                
                                </v-row>
                            </div>
                    </v-col>
                
                        <v-col cols="12">
                            <div class='componentWraper'>
                                <p class='componentTitle'>Documents</p>
                                <gallery v-if="!showpdf" :images="docs" :index="image_index" @close="image_index = null">
                              
                                </gallery>
                             
                                <v-row v-if="userdoc.length > 1">
                                    <v-col cols="3" v-for="(item,i) in userdoc" :key="item.doc_name">
                                        <v-list-item-content > 
                                         <v-row align="center" justify="center">
                                              <v-card  width="100%" class="overlay_card">
                                              <v-overlay :value="showpdf" class="overlay">
                                                    <v-card-title class="overlay_title">
                                               
                                                <a :href="pdfname"  download target="_blank" color="green  darken-1">Download {{item.doc_name}}</a>
                                                 <v-spacer></v-spacer>
                                                <v-btn icon color="green darken-1" text @click="closePdfModal()"><v-icon size="50">mdi-close</v-icon></v-btn>
                                                </v-card-title>
                                                <pdf  :src="pdfname" >
                                                </pdf>
                                               
                                            </v-overlay>
                                             </v-card>
                                 
                                        </v-row>

                                             <div>
                                            
                                             </div>
                                                <v-icon size="50" @click="checkForFileLinkExtension(item.filename)?goImageIndex(i):showPdfModal(item.filename)">mdi-file-outline</v-icon>
                                                <v-list-item-title class="text-bold text-center text-font">{{item.doc_name}}</v-list-item-title>
                                            </v-list-item-content>
                                            
                                    </v-col>
                                </v-row>
                                <div class="primary download-link" @click="downloadAllFiles($event)">Download All Files
                                    <a v-for="(file, i) in files" :key="i" :href="file" :download="fileToDownload[i].doc_name"></a>
                                </div>
                            </div>
                    </v-col>
                </v-row>
            </v-card-text>
       </div>
     
   </v-card>

    <v-dialog v-model="approvedDialog" persistent max-width="350">
        <v-card>
            <v-card-title class=""> Are you sure to approved ?</v-card-title>
                
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="approvedDialog = false">No</v-btn>
                <v-btn  :loading="apiloading" :disabled="apiloading" color="success darken-1" text @click.prevent=" approvedMort()"    >Yes</v-btn>
                
                </v-card-actions>
            
        </v-card>
    </v-dialog>
     <v-dialog v-model="disapprovedDialog" persistent max-width="350">
        <v-card>
            <v-card-title class=""> Decline Mortgage</v-card-title>
            <form ref="declineform">
                <v-card-text>
                    <!-- <p v-if="err" style="color:red">This is required</p> -->
                    <v-textarea
                        outlined
                        v-model="decline_form.reason"
                        ref="reason"
                        name="reason"
                        label="Reason"
                         :rules="[() => !!decline_form.reason || 'This field is required']"
                        :error-messages="decline_form_errorMessages"
                        ></v-textarea>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="closeDisaprroedFrom()">Cancel</v-btn>
                    <v-btn  :loading="apiloading" :disabled="apiloading" color="success darken-1" text @click.prevent=" disApprovedMort()"    >Send</v-btn>
                </v-card-actions>
            </form>
                
        </v-card>
    </v-dialog>
    <!-- docu modal -->
    <v-row justify="center">
        <v-dialog v-model="docmodal" persistent max-width="350">
        <v-card>
            <v-card-title class="headline">Require Documents</v-card-title>
            <form ref="doc_form">
                        <v-card-text>
                           <v-select
                                v-model="docform.required_doc"
                                :items="documents"
                                ref="required_doc"
                                name="required_doc"
                                item-text="name"
                                item-value="id"
                                label="Required Documents"
                                  outlined
                                multiple
                                  :rules="[() => !!docform.required_doc || 'This field is required']"
                                    :error-messages="docform_errorMessages"
                            ></v-select>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closeModal()">Close</v-btn>
                            <v-btn type="submit" :loading="apiloading" :disabled="apiloading" color="success darken-1"     @click.prevent="requiredDocument()" >Send</v-btn>
                        </v-card-actions>
            </form>
            
        </v-card>
        </v-dialog>
    </v-row>
        </v-container>
    <success-card :text="success"></success-card>
    </div>
</template>
<script>
import {loadingMixin} from '../../../mixins/Loading'
import {formMixin} from '../../../mixins/form'
import {mortgageMixin} from '../../../mixins/mortgage'
import EventBus from '../../../services/event'
export default {
   
    mixins:[loadingMixin,mortgageMixin,formMixin],
    data() {
        return {
            currentPage: 0,
			pageCount: 0,
            showLitebox:false,
            visible:false,
            approvedDialog:false,
            disapprovedDialog:false,
            docmodal:false,
            err:false,
            slug:this.$route.params.slug,
            success:'',
            decline_form:{
                reason:''
            },
            decline_form_errorMessages:'',
            decline_form_errorMessages_hasError:false,
            docform:{
                required_doc:[]
            },
            docform_errorMessages:'',
            docform_errorMessages_hasError:false,
            action_dropdown: [
                { text: 'Approved',action:'Approved'},
                { text: 'Declined', action:'Declined' },
                { text: 'Request For Document',action:'RequestDoc'},
                { text: 'Cancel', action:'Cancel'},
            ],
            docs:[ ],
            fileToDownload:[],
            image_index:null,
            isPdf:false,
            showpdf:false,
            pdfname:'',
            files: []
        }
    },
    watch:{
		'$route'(to, from){
			this.slug = to.params.slug

        },
        // pdfname:function(newvalue,oldvalue){
        //     console.log(newvalue +':'+oldvalue)
        // }
	},
    computed: {
        details(){
        return this.$store.state.regular_mortgage_module.mortgage_details
    },
    userdoc(){
        let d =this.$store.state.regular_mortgage_module.user_doc;
        this.fileToDownload = d;
        let newfiles = [];
         
        if(typeof(d) != 'string'){
            d.filter((c,i)=>{
                newfiles[i]=c.filename
            if(c.filename != null){
                this.docs.push(c.filename);
            } // console.log(c.filename
            this.getAllFiles(newfiles);
        })
        return d;
        }
        //  return d;
    },
  
    documents(){
        let d = this.$store.state.regular_mortgage_module.all_documents
        return d;
    },
    declineform(){
        return{
            reason:this.decline_form.reason
        }
    },
    doc_form(){
        return{
            required_doc:this.docform.required_doc
        }
    }
   
    },
    methods: {
        checkForFileLinkExtension(t){
            let link = t.split(".");
            let l = link.length;
            let ext = link[l-1];
            if(ext != 'pdf'){
                return true;
            }
            return false;    
        },
        goBack(){
            this.$router.go(-1);
        },
        showMortgageDetail(){
            this.$store.dispatch("mortgageDetailAction",this.slug);
            // if(type == 'nhf'){
            //     this.$store.state.regular_mortgage_module.nhf_mortgage.filter(function(h){
            //         if(h.slug == slug){

            //         }
            //     })
            // }else{
            //     this.$store.state.regular_mortgage_module.regular_mortgage
            // }
        },
        showListPage(){
            this.$router.go(-1);
        },
        checkNull(v){
            if(typeof(v) != 'undefined' || v !=null){
                return v;
            }
            return 'N/A'
        },
        approvedMort(){
                this.$store.dispatch("approvedMortgageAction",this.slug).then((r)=>{
                    this.approvedDialog = false;
                    this.success = "Mortgage Application Approved"
                    EventBus.$emit("show_success",true)
                    this.$store.dispatch("mortgageDetailAction",this.slug)

            });
        },
        disApprovedMort(){
            if (this.validateForm(this.decline_form)) {
                let d = {form:this.decline_form,slug:this.slug}
                this.$store.dispatch("declinedMortgageAction",d).then((res)=>{
                    this.disapprovedDialog=false;
                    this.success = "Mortgage Application Disapproved"
                    EventBus.$emit("show_success",true)
                    this.resetForm(this.decline_form);
                //   this.$store.dispatch("mortgageDetailAction",this.details.slug)
            })
            }else{
                this.err = true;
                return;
            }
            
        },
        requiredDocument(){
                    
            if(this.validateForm(this.docform)){
                this.docform_errorMessages_hasError=false
                let data={form:this.docform,slug:this.details.slug}
            this.$store.dispatch("requestForDocumentAction",data).then((res)=>{
                this.docmodal=false;
                    this.success = "Required Documents sent"
                    EventBus.$emit("show_success",true)
                this.resetForm(this.docform);
                this.docform=''
            
            })
        }else{
            // this.docform_errorMessages_hasError = true
            return;
        }     
        },
        dropdownaction(action){
            switch (action) {
                case 'Approved':
                    this.approvedDialog = true;
                break;
                case 'Declined':
                        this.disapprovedDialog=true;
                break;
                    case 'RequestDoc':
                    this.docmodal = true
                break;
            }
        },
        closeModal(){
            this.docmodal=false;
            this.docform_errorMessages_hasError = false
                this.docform=''
        },
        closeDisaprroedFrom(){
            this.disapprovedDialog=false,
            this.err=false;
            this.decline_form = ''
        },
        allDoc(){
            this.$store.dispatch("allDocumentAction");
        },
        handleHide() {
            this.visible = false
        },
        showAllDoc(){
            this.visible=true
        },

        goImageIndex(i){
            this.image_index = i;
        },
        showPdfModal(t){
            this.showpdf=true;
            this.pdfname = t;
            this.image_index = null;
        },
        closePdfModal(){
            this.pdfname = '';
            this.showpdf=false;
                this.image_index = null;
        },
        print() {
                // Pass the element id here
                this.$htmlToPaper('printMe');
                



                },
        downloadPdfModal(file,name){
                     var fileURL = window.URL.createObjectURL(new Blob([file]));
                     var fileLink = document.createElement('a');
   
                     fileLink.href = fileURL;
                     fileLink.setAttribute('download', name+'.'+'pdf');
                     document.body.appendChild(fileLink);
   
                     fileLink.click();
                // this.myResult = this.myString.split(" ");
            
        },
        downloadAllFiles({ target }){  
            Array.from(target.querySelectorAll('a')).forEach(el => el.click());           
        },
        getAllFiles(urls){
            Promise.all(urls.map(async (url) => {
                const blob = await fetch(`https://cors-anywhere.herokuapp.com/${url}`).then(res => res.blob());
                return URL.createObjectURL(blob);
            })).then(fls => { this.files = fls }).catch(e => console.log(e));
        
        },
        revokeFile(){
            this.files.forEach(file => URL.revokeObjectURL(file));
        }

    },
      
    
    created() {
        this.allDoc();
        this.showMortgageDetail();
       
    },
    beforeDestroy() { this.revokeFile(); }
}
</script>
<style >
.download-link{
    width:150px;
    cursor: pointer;
    text-align: center;
    color: #fff;
    height: 30px;
    padding:4px ;

}
  .componentWraper {
  margin: 20px; 
  position: relative;
  border: 2px solid #3f87f5;
  border-radius: 12px;
  padding: 10px;
}

.componentWraper .componentTitle {
  position: absolute;
  top: -20px;
  background: #fff;
  padding: 0 10px;
} 
.lender_logo2{
    background-size: cover;
    width:150px;
    max-width: 250px;
    height: 80px;
    background-position:center; 
    background-repeat: no-repeat;
    border-radius: 5px;
}
.text-bold{
    font-weight: bolder
}
.vel-img[data-v-4de35050] {
    background-color: snow !important;
}
.v-overlay__content{
    width:50%;
    text-align: center;
    height: 60%;
}

/* .v-overlay__content span { */
    /* text-align: center;
    width:100% ;
    overflow-y:scroll; */
/* } */
.overlay__content span::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
}
.overlay{
    height:100%;
    /* overflow-y: scroll; */
    /* width:50% !important; */
}
.overlay_card{
    height: 80%;
    /* overflow-y: scroll; */
    /* width:50% !important; */
}
.text-font{
    font-size:11px;
    
}
</style>