import Vue from 'vue'
import VueLitebox from 'vue-litebox'
import pdf from 'vue-pdf'
import VueGallery from 'vue-gallery';
import VuePDFViewer from 'vue-instant-pdf-viewer'




//layouts
import Frontend from '../views/layouts/Frontend.vue'
import Backend from '../views/layouts/Backend.vue'

import MyLoader from './general/MyLoader.vue'
import Success from './general/Success.vue'
import NavList from './general/NavList.vue'
import Breadcrum from './general/Breadcrum'
import Table from "./general/Table";
import BarChart from './charts/BarChart'
import SummaryCard from "./summary/SummaryCard";



Vue.component('frontend-layout', Frontend);
Vue.component('backend-layout', Backend);


Vue.component('my-loader', MyLoader);
Vue.component('success-card', Success);
Vue.component('nav-list', NavList);
Vue.component('breadcrum', Breadcrum);
Vue.component(VueLitebox);
Vue.component('pdf', pdf);
Vue.component('gallery', VueGallery);

Vue.component('bar-chart', BarChart);
Vue.component('my-table',Table);
Vue.component('summary-card',SummaryCard);


//component 
// Vue.component('listcard', ListCard);//ohome page
// Vue.component('by-location-card', ByLocationCard);//ohome page
// Vue.component('mylistingcard', MyListingCard);
// Vue.component('mycollectioncard', MyCollectionCard);
// Vue.component('property-list-card',PropertyListCard);