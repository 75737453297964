<template>
    <div>
        <v-data-table :loading="apiloading" loading-text="Loading... Please wait"
                      :headers="header"
                      :items="data"
                      :items-per-page="10"
                      :search=search
                      class="elevation-1"
                      :single-expand="singleExpand"
                      show-expand
        >
<!--            <slot name="table-slot"></slot>-->
           <template v-slot:item.data-table-expand>
               <v-icon  medium class="mr-2" >mdi-eye-circle-outline
               </v-icon>
           </template>
            <template v-slot:expanded-item="{ item }">
                <div style="width: 100%;min-height:300px;">

                </div>
                <!--                           <td :colspan="headers.length">More info about {{ item.firstname }}</td>-->
            </template>
        </v-data-table>
    </div>
</template>

<script>
    export default {
        name: "Table",
        props:["data","header"]
    }
</script>

<style scoped>

</style>