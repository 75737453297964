
<template>
  <v-app id="inspire">
    <v-navigation-drawer class="no-print"
      v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>    
      <v-list dense>
          <!-- <nav-list :data=" { icon: 'mdi-home', text: 'Dashboard',link:'home' }"></nav-list> -->
          <!-- <nav-list :data=" { icon: 'mdi-file-multiple-outline', text: 'All Applications',link:'all_mortgage' }"  v-if="!user.isAdmin==1"></nav-list> -->
         
          <nav-list :data=" { icon: item.icon, text: item.text,link:item.link,children:item.children,prepend_icon:item.prepend_icon,heading:item.heading }"  v-for="(item,i) in navigation_menu" :key="i" :hasChildren=item.hasChildren :show_heading="item.show_heading" :roles="item.role" ></nav-list>
          <!-- <nav-list :data=" { icon: 'mdi-account-group', text: 'Staff',link:'staff' }" v-if="user.is_admin==1"></nav-list>
            <nav-list :data="{
              icon: 'mdi-chevron-up',
              'icon-alt': 'mdi-file-multiple-outline',
              text: 'All Applications Two',
               icon: 'mdi-chevron-up',
              model: false,
              children: [
                { icon: 'mdi-file-outline', text: 'Pending Application',link:'pending_mortgage' },
                { icon: 'mdi-file-outline', text: 'Approved Applications',link:'approved_mortgage' },
                { icon: 'mdi-file-outline', text: 'My Assigned Applications',link:'assigned_mortgage' },
              ],}" :hasChildren=true>
            </nav-list> -->
         

      </v-list>
    </v-navigation-drawer>

    <v-app-bar class="no-print"
      :clipped-left="$vuetify.breakpoint.lgAndUp" elevation="1" app color="white"
    >
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title
        style="width: 300px"
        class="ml-0 pl-4"
      >
        <v-img alt="Newhomes" class="shrink mr-2"  contain  src="../../assets/logo.png" width="100px" height="20px" />
      </v-toolbar-title>
       <!-- <v-text-field
      solo
       hide-details
            label="Search"
            append-icon="mdi-magnify"
          ></v-text-field> -->
         
      <v-spacer />
      <v-spacer />
      <template >
          <!-- <div class="padme_ho" style="border-right:1px solid #f2f2f2;"> -->
              <v-toolbar-title style="width: 150px;border-right:1px solid #f2f2f2; text-align:center" class="ml-0 padme_ho"
      >
        <!-- <v-img alt="Newhomes" class="shrink mr-2"  contain  src="../../assets/logo.png" width="100px" height="20px" /> -->
        <div :style="{backgroundImage:'url('+lender.lender_logo+')'}" class="lender_logo"></div>
      </v-toolbar-title>
          <!-- </div> -->
            <!-- <h4 style="margin-left:5px;border1px solid #f2f2f2;">{{getHumanDate}}</h4> -->
            <!-- <v-divider :vertical="true"></v-divider> -->
        <v-spacer></v-spacer>
        <div class="d-none d-sm-flex">
            <!-- <h4>{{getHumanDate}}</h4> -->
          <v-spacer></v-spacer>
             <h4>{{user.role | upper}} : {{user.firstname}}  </h4>
        </div>
       
        <v-menu bottom origin="center center" transition="scale-transition" class="profile_icon">
          <template v-slot:activator="{ on }">
            {{user.name}}
            <v-btn icon v-on="on" outlined class="mr-5 ml-5" >
            <v-icon size="30">mdi-account</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, i) in user_menu"
              :key="i"
              @click="user_menu_action(item.link)"
            >
              <v-list-item-title ><v-icon >{{item.icon}}</v-icon> {{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
        <!-- <v-img alt="Newhomes" class="shrink mr-2"  contain  src="../../assets/logo.png" width="100px" height="20px" /> -->
          <!-- <v-spacer /> -->
       
      <!-- <v-btn icon>
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn> -->
     

    </v-app-bar>
    <v-content>
      <!-- <v-container
        class="fill-height"
        fluid
      > -->
       <breadcrum class="no-print"/>
        <router-view></router-view>
      <!-- </v-container> -->
    </v-content>
   
    <v-dialog
      v-model="dialog"
      width="800px"
    >
      <v-card>
        <v-card-title class="grey darken-2">
          Create contact
        </v-card-title>
        <v-container>
          <v-row class="mx-2">
            <v-col
              class="align-center justify-space-between"
              cols="12"
            >
              <v-row
                align="center"
                class="mr-0"
              >
                <v-avatar
                  size="40px"
                  class="mx-3"
                >
                  <img
                    src="//ssl.gstatic.com/s2/oz/images/sge/grey_silhouette.png"
                    alt=""
                  >
                </v-avatar>
                <v-text-field
                  placeholder="Name"
                />
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-text-field
                prepend-icon="mdi-account-card-details-outline"
                placeholder="Company"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                placeholder="Job title"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-mail"
                placeholder="Email"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="tel"
                prepend-icon="mdi-phone"
                placeholder="(000) 000 - 0000"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-text"
                placeholder="Notes"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn
            text
            color="primary"
          >More</v-btn>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="dialog = false"
          >Cancel</v-btn>
          <v-btn
            text
            @click="dialog = false"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-footer>
      FinancePlus
    <v-spacer></v-spacer>
    <div>&copy; {{ new Date().getFullYear() }}</div>
  </v-footer>
  </v-app>
</template>

<script>
import moment from 'moment'
import {loadingMixin} from '../../mixins/Loading'
  export default {
    mixins:[loadingMixin],
    props: {
      source: String,
    },
    data: () => ({
      dialog: false,
      drawer: null,
      userCanApproved:false,
      user_menu: [
        { title: 'Profile',icon:'mdi-account',link:'profile' },
        { title: 'Sign Out',icon:'mdi-logout',link:'logout' }
      ],
      navigation_menu: [
        { icon: 'mdi-home', text: 'Dashboard',link:'home',show_heading:false,role:["reviewer","approval"] },
        { icon: 'mdi-moon-new', text: 'New Applications' ,link:'new_application' , hasChildren:false,show_heading:true,heading:'Applications',role:["reviewer"]},
        { icon: 'mdi-car-brake-parking', text: 'Pending Applications' ,link:'pending_application',hasChildren:false,show_heading:false,role:["reviewer","approval"]},

        { icon: 'mdi-cancel', text: 'Declined Applications' ,link:'declined_application',hasChildren:false,show_heading:false,role:["reviewer","approval"]},
        { icon: 'mdi-adjust', text: 'Query Applications' ,link:'queried_application',hasChildren:false,show_heading:false,role:["reviewer","approval"]},
        { icon: 'mdi-alert-circle-check-outline', text: 'Verified Applications' ,link:'verified_application',hasChildren:false,show_heading:false,role:["reviewer","approval"]},
        { icon: 'mdi-collapse-all-outline', text: 'Pending Verified Applications' ,link:'pending_verified_application',hasChildren:false,show_heading:false,role:["approval"]},
        // {
        //   icon: 'mdi-arrow-down', icon_alt: 'mdi-comma-circle', prepend_icon:'mdi-av-timer', text: 'Aged Applications', model: false,
        //   hasChildren:true,
        //   show_heading:false,
        //   role:["reviewer","approval"],
        //   children: [
        //     { icon: 'mdi-file-outline', text: 'Last 7 Days',link:'regular_mortgage6' },
        //     { icon: 'mdi-file-outline', text: 'Last 14 Days',link:'nhf_mortgage7' },
        //     { icon: 'mdi-file-outline', text: 'Over Months',link:'nhf_mortgage8' },
        //   ],
        // },
        { icon: 'mdi-progress-check', text: 'Approved Applications' ,link:'approved_application',hasChildren:false,show_heading:false,role:["approval"]},
        
        { icon: 'mdi-account-group', text: 'Staff',link:'staff',show_heading:true,heading:"Users",role:["approval"]},
   
      ],
    }),
    computed:{
      	getHumanDate(){
            let date = new Date();
            return moment(date).format('dddd,  Do MMMM, YYYY');
              // return moment(date).format('dddd,  Do MMMM, YYYY, h:mm:ss a');
          },
        user(){
          let p = this.$store.state.authmodule.user;
          let role = p.role;
          if(role !='reviewer'){
            this.userCanApproved = true;
          }else{
            this.userCanApproved=false;
          }
          console.log(p.role);
          return p;
        },
        lender(){
           return this.$store.state.authmodule.lender;
        }
       
    },
    methods:{
        user_menu_action(link){
            if(link == 'logout'){
                this.logout();
            }else{
                 if (this.$route.path !== link){
                    this.$router.push({name:link});
                 }
          }
        },
        goLink(link){
          if (this.$route.name !== link){
            this.$router.push({name:link});
          }
         
        },
        logout(){
            this.$store.dispatch('logout')
        }
    }
  }
</script>
<style >
.profile_icon{
  cursor: pointer;
}
.padme_ho{
  padding:0 20px;
}
.mycontainer{
  width:100% !important;
  max-width:100% !important;
}
.active{
  background-color:#0168f3;
  color:aliceblue;
}
.router-link-active{
	opacity: 1;
    color: #0168fa;
    font-weight: 500;
}
.router-link-exact-active{
	opacity: 1;
    color: #0168fa;
    font-weight: 500;
}
.v-list-item--active{
   color: #fff !important;
   background-color:#a8cfda !important;
}
.lender_logo{
   width:100px;
   height:50px;
   max-width:200px;
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
}
/* .theme--light.v-list-item:(.v-list-item--active):not(.v-list-item--disabled) {
    color: rgba(0, 0, 0, 0.87) !important;
} */
.v-data-table-header{
  background-color: #f2f2f2;
  color:#fff !important;
}
</style>