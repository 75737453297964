<template>
<div>
    <v-container class="mycontainer">
            <v-card>
         <my-loader></my-loader>
        <v-card-title>
            <v-row>
                <v-col cols="12" md="4">{{current_staff.firstname}} Applications</v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                        v-model="search"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                    :items="mortgagestatus"
                    v-model="search"
                      item-text="status"
                        item-value="status"
                    label="Filter By Status"
                    ></v-select>
                </v-col>
              
            </v-row>
            <!-- All Applications
            <v-spacer></v-spacer>
                  <v-select
                    :items="[]"
                    label="Standard"
                    ></v-select>
            <v-spacer></v-spacer>
            <v-text-field
            v-model="search"
          
            label="Search"
            single-line
            hide-details
        ></v-text-field> -->
        </v-card-title>
        <v-card-text>
             <div class="m-t-25 ">
                     <v-data-table
                        :headers="headers"
                        :items="mortgages"
                        :items-per-page="5"
                        :search=search
                        class="elevation-1"
                    >
                            <template v-slot:item.mortgage_status_name="{ item }">
                                <v-chip small class="ma-2 text-white" :color="showMortgageStatus(item.mortgage_status_name)">{{item.mortgage_status_name}}</v-chip>
                                    
                            </template>
                            <template v-slot:item.firstname="{ item }">
                                    {{item.firstname}} {{item.lastname}}
                            </template>
                             <template v-slot:item.loan_amount="{ item }">
                                    {{item.loan_amount| currency}}
                            </template>
                             <template v-slot:item.total_annual_pay="{ item }">
                                    {{item.total_annual_pay| currency}}
                            </template>
                              <template v-slot:item.loan_tenure="{ item }">
                                    {{item.loan_tenure}} years
                            </template>
                              <template v-slot:item.action="{ item }">
                               

                                    <!-- <v-btn color="primary" dark>{{item.id}}</v-btn> -->
                            <v-icon color="primary" medium class="mr-2" @click="showDetailPage(item.slug)">mdi-eye-circle-outline
                            </v-icon>
                            <v-icon color="green" medium class="mr-2" @click="showAddUserDialog(item)">mdi-account
                            </v-icon>
                            </template>
                    </v-data-table>
                     </div>
        </v-card-text>
    </v-card>
    <!-- add user dialod -->
    <div class="text-center">
    <v-dialog v-model="addStaffDialog" width="500">
      <v-card>
        <v-card-title
          class="headline primary lighten-2 text-center"
          color="#fff"
          
        >
          Assign Staff to {{application.app_ref }}   {{application.lender_user_id}}
        </v-card-title>
          <v-form ref="assign_form">
            <v-card-text>
                
                    <v-radio-group v-model="assignstaff_form.staff"   ref="staff" name="staff" column   :rules="[rules.required]"
                    :error-messages="assignstaff_form_errorMessages">
                      <v-radio  v-for="(s, i) in staff" :key="i" v-bind:checked="s.id==application.lender_user_id"
                       v-bind:label="s.firstname" :value="s.id"></v-radio>
                      <!-- <v-radio label="Option 2" value="radio-2"></v-radio> -->
                    </v-radio-group>
                
            </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
               <v-btn
              color="primary"
              text
              @click="closeModal()"
            >
              Close
            </v-btn>
            <v-spacer></v-spacer>
           
                    <v-btn type="submit" :loading="apiloading" large :disabled="apiloading" color="primary" @click.prevent="assignStaffToMortgage(application.slug)">Assign Staff </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>

    </v-container>
    <success-card :text="success_text"></success-card>
</div>
    
</template>

<script>
import {loadingMixin} from '../../../mixins/Loading';
import {mortgageMixin} from '../../../mixins/mortgage';
import { formMixin } from '../../../mixins/form';
import EventBus from '../../../services/event'
    export default {
        name:"Nhf",
        mixins:[loadingMixin,mortgageMixin,formMixin],
        data(){
            return{
              staff_slug:this.$route.params.slug,
                filterby:'',
                addStaffDialog:false,
                success_text:'',
                application:'',
                search:'',
                status_color:'',
                headers:[
                { text: 'Status', value: 'mortgage_status_name' },
                { text: 'Date', value: 'created_at' },
                   { text: 'Type', value: 'type' },
                {text: 'Ref No',align: 'left',sortable: false,value: 'app_ref' },
                { text: 'Customer', value: 'firstname' },
                { text: 'Loan Amount', value: 'loan_amount' },
                { text: 'Staff', value: 'lenderuser.firstname' },
                { text: 'Loan Tenure', value: 'loan_tenure' },
              
                {text:'Action',value: 'action', sortable: false }
                ],
                assignstaff_form:{
                  staff:''
                },
                assignstaff_form_errorMessages:'',
                assignstaff_form_hasError:false,
                 rules: {
                required: value => !!value || 'Select a Staff',
                counter: value => value.length >= 6 || 'Min 6 characters',
                  email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
                 }
            }
        },
        watch:{
		      '$route'(to, from){
			    this.staff_slug = to.params.slug;

        }
        },
        computed: {
            mortgages(){
                 let p = this.$store.state.regular_mortgage_module.all_staff_mortgage_application;
                 let new_p=[];
                // if(this.filterby !=''){
                //     p.filter(function(item){
                //         if(item.mortgage_status_name == this.filterby){
                //             new_p.push(item);
                //             return new_p;
                //         }
                //     })
                // }
               
                return p
            },
            mortgagestatus(){
                let p = this.$store.state.regular_mortgage_module.all_mortgage_status;
                // let statusname = [];
                // p.map(function(item){
                //     console.log(item);
                //     statusname.push(item.status);
                // })
                return p;
            },
            staff(){
                let p = this.$store.state.staff_module.staff;

                return p
            },
            current_staff(){
              return  this.$store.state.staff_module.current_staff;
            },
           
            assign_form(){
                return{
                  staff:this.assignstaff_form.staff
                }
            }
        },
        methods: {
            filterme(value, search, item){

            },
            assignStaffToMortgage(slug){
                if(this.validateForm(this.assignstaff_form)){
                    let data = {form:this.assignstaff_form,slug:slug};
                    this.$store.dispatch("addStaffToMortgageAction",data).then((res)=>{
                        this.addStaffDialog = false;
                        this.success_text="User Assigned Successfully";
                        EventBus.$emit("show_success",true)
                        this.resetForm(this.assignstaff_form);
                        this.allMortgage();
                    })
                }else{
                  return;
                }
            },
            allStaffMortgage(){
                this.$store.dispatch("allStaffMortgageAction",this.$route.params.slug);
            },
            allMortgageStatus(){
                this.$store.dispatch("allMortgageStatusAction");
            },
            allStaff(){
                this.$store.dispatch("allStaffListAction")
            },
            showMortgageStatus(st){
                switch (st) {
                    case 'Submitted':
                        return 'blue'
                        break;
                    case 'Approved':
                    return 'green'
                    break;
                    case 'Verifying':
                    return 'blue'
                    break;
                
                    default:
                        break;
                }
            },
            showAddUserDialog(item){
                this.addStaffDialog=true;
                this.application = item
            },
            closeModal(){
              this.addStaffDialog =false;
            }
        },
        created() {
            this.allStaffMortgage();
            this.allMortgageStatus();
            this.allStaff();
            // this.getCurrentStaff();
        },
    }
</script>
<style scoped>
.v-application .primary.lighten-2 {
  color:#fff;
}
.v-list-item--active {
    /* color: #fff !important; */
    background-color: #a8cfda !important;
}
</style>