import Home from '../views/backend/Home.vue'
// import RegularMortgage from '../views/backend/mortgage/RegularMortgage.vue'
// import NhfMortgage from '../views/backend/mortgage/NhfMortgage.vue'
// import ApprovedMortgage from '../views/backend/mortgage/ApprovedMortgage.vue'
// import PendingMortgage from '../views/backend/mortgage/PendingMortgage.vue'
import MyMortgage from '../views/backend/mortgage/MyMortgage.vue'
import AllMortgage from '../views/backend/mortgage/AllMortgage.vue'
import AllStaffMortgage from '../views/backend/mortgage/AllStaffMortgage.vue'
import MortgageDetail from '../views/backend/mortgage/MortgageDetail.vue'
import Staff from '../views/backend/user/Staff.vue'
import Profile from '../views/backend/Profile.vue'

const backend_routes = [{
        path: '/home',
        name: 'home',
        meta: {
            title: "Dashboard",
            path_name: "dashboard",
            layout: "backend",
            authorize: ['approval', 'reviewer']
        },
        component: () => import(/* webpackChunkName: "home" */'@/views/backend/Home')
    },
    {
        path: '/new-applications',
        name: 'new_application',
        meta: {
            title: "New Applications",
            path_name: "Application",
            layout: "backend",
            authorize: ['reviewer']
        },
        component: () => import(/* webpackChunkName: "new" */'@/views/backend/applications/NewApplication')
    },
    {
        path: '/pending-applications',
        name: 'pending_application',
        meta: {
            title: "Pending Applications",
            path_name: "Application",
            layout: "backend",
            authorize: ['approval','reviewer']
        },
        component: () => import(/* webpackChunkName: "pending" */'@/views/backend/applications/PendingApplication')
    },
    {
        path: '/declined-applications',
        name: 'declined_application',
        meta: {
            title: "Declined Applications",
            path_name: " Application",
            layout: "backend",
            authorize: ['approval','reviewer']
        },
        component: () => import(/* webpackChunkName: "declined" */'@/views/backend/applications/DeclinedApplication')
    },
    {
        path: '/queried-applications',
        name: 'queried_application',
        meta: {
            title: "Queried Applications",
            path_name: " Application",
            layout: "backend",
            authorize: ['approvale','reviewer']
        },
        component: () => import(/* webpackChunkName: "query" */'@/views/backend/applications/QueriedApplication')
    },
    {
        path: '/verified-applications',
        name: 'verified_application',
        meta: {
            title: "Verified Applications",
            path_name: " Application",
            layout: "backend",
            authorize: ['approval','reviewer']
        },
        component: () => import(/* webpackChunkName: "verified" */'@/views/backend/applications/VerifiedApplication')
    },
    {
        path: '/pending-verified-applications',
        name: 'pending_verified_application',
        meta: {
            title: "Pending Verified Applications",
            path_name: " Application",
            layout: "backend",
            authorize: ['approval']
        },
        component: () => import(/* webpackChunkName: "verified" */'@/views/backend/applications/PendingVerifiedApplication')
    },
    {
        path: '/approved-applications',
        name: 'approved_application',
        meta: {
            title: "Approved Applications",
            path_name: " Application",
            layout: "backend",
            authorize: ['approval']
        },
        component: () => import(/* webpackChunkName: "verified" */'@/views/backend/applications/ApprovedApplication')
    },
    {
        path: '/mortgage-summary/:slug',
        name: 'mortgage_summary',
        meta: {
            title: "Application Report",
            path_name: "Summary",
            step:"Applications",
            layout: "backend"
        },
        component: () => import(/* webpackChunkName: "verified" */'@/views/backend/applications/Summary')
    },
    {
        path: '/all-mortgage',
        name: 'all_mortgage',
        meta: {
            layout: "backend",
            authorize: ['approval', 'reviewer']
        },
        component: AllMortgage
    },
    {
        path: '/staff-assigned-mortgage/:slug',
        name: 'staff-assigned-mortgage',
        meta: {
            layout: "backend",
            authorize: ['approval', 'reviewer']
        },
        component: AllStaffMortgage
    },
    // {
    //     path: '/approved-mortgage',
    //     name: 'approved_mortgage',
    //     meta: {
    //         layout: "backend"
    //     },
    //     component: ApprovedMortgage
    // },
    // {
    //     path: '/pending-mortgage',
    //     name: 'pending_mortgage',
    //     meta: {
    //         layout: "backend"
    //     },
    //     component: PendingMortgage
    // },
    {
        path: '/assigned_mortgage',
        name: 'assigned_mortgage',
        meta: {
            layout: "backend",
            authorize: ['approval', 'reviewer']
        },
        component: MyMortgage
    },
    // {
    //     path: '/regular-mortgage',
    //     name: 'regular_mortgage',
    //     meta: {
    //         layout: "backend"
    //     },
    //     component: RegularMortgage
    // },
    // {
    //     path: '/nhf-mortgage',
    //     name: 'nhf_mortgage',
    //     meta: {
    //         layout: "backend"
    //     },
    //     component: NhfMortgage
    // },
    {
        path: '/mortgage-detail',
        name: 'mortgage_detail',
        meta: {
            title: "Application Details",
            path_name: "Details",
            step:"Applications",
            layout: "backend"
        },
        component: () => import(/* webpackChunkName: "verified" */'@/views/backend/applications/Details')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {
            layout: "backend"
        },
        component: Profile
    },
    {
        path: '/staff',
        name: 'staff',
        meta: {
            title: "All Staff",
            path_name: "Staff",
            layout: "backend",
            authorize: ['approval']
        },
        component: Staff
    },
    {
        path: '/not-authorize',
        name: 'not_authorize',
        meta: {
            layout: "backend",
            public: true,
            onlylogout: true,
            // middleware: [ auth]
        },
        component: () =>
            import( /* webpackChunkName: "about" */ '../views/backend/Notauthorize.vue')
    }
]

export default backend_routes