import { ApiSource } from "../../services/ApiService";
import {MortgageApiSource} from '../../services/MortgageApiService';
import { TokenService } from "../../services/TokenService";
const mortgageapi = new MortgageApiSource();
const api = new ApiSource();

// const app_secret = TokenService.getAppSecret();
// const api = new MortgageApiSource("https://app.financeplus.ng/api/domain/"+app_secret+"/");
// const genralapi = new MortgageApiSource("https://app.financeplus.ng/api/");
const STATUS = [
    {status:'pending',mutation:"PENDING_MORTGAGE"},
    {status:'verify',mutation:"VERIFIED_MORTGAGE"},
    {status:'decline',mutation:"DECLINED_MORTGAGE"},
    {status:'query',mutation:"QUERIED_MORTGAGE"},
    {status:'approve',mutation:"APPROVED_MORTGAGE"},
]

const state={
    agent:'',
    all_documents:[],
    all_mortgage_status:[],
    pending_mortgage:[],
    verified_mortgage:[],
    declined_mortgage:[],
    queried_mortgage:[],
    approved_mortgage:[],
    new_mortgage:[],
    all_mortgage:[],
    recent_mortgage:[],
    staff_recent_mortgage:[],
    all_mortgage_application:[],
    all_staff_mortgage_application:[],
    mortgage_details:{},
   show_mortgage_page:false,
   user_doc : '',
   dashboard:{
       total_received:'',
       total_approved:'',
       total_pending:'',
       total_received_value:'',
       total_approved_value:'',
       total_pending_value:'',
       total_approved_customer:'',
       total_pending_customer:'',
   }
}
const mutations={
    DASHBOARD_INFO(state,payload){
        state.dashboard.total_received = payload.total_received
        state.dashboard.total_approved = payload.total_approved
        state.dashboard.total_pending = payload.total_pending
        state.dashboard.total_received_value = payload.total_received_value
        state.dashboard.total_approved_value = payload.total_approved_value
        state.dashboard.total_pending_value = payload.total_pending_value
        state.dashboard.total_approved_customer = payload.total_approved_customer
        state.dashboard.total_pending_customer = payload.total_pending_customer

    },

    RECENT_MORTGAGE_LIST(state,payload){
        state.recent_mortgage=payload
    },
    STAFF_RECENT_MORTGAGE_LIST(state,payload){
        state.staff_recent_mortgage=payload
    },
     ALL_MORTGAGE_LIST(state,payload){
        state.all_mortgage_application=payload
    },
     ALL_STAFF_MORTGAGE_LIST(state,payload){
        state.all_staff_mortgage_application=payload
    },

    MORTGAGE_DETAILS(state, payload){
        state.mortgage_details = payload

    },
    ALL_DOCUMENTS(state,payload){
        state.all_documents = payload
    },
    ALL_MORTGAGE_STATUS(state,payload){
        state.all_mortgage_status = payload
    },
    SHOW_MORTGAGE_PAGE(state,payload){
        state.show_mortgage_page = payload
    },
    USER_DOC(state,payload){
        state.user_doc = payload
    },
    PENDING_MORTGAGE(state,payload){
        state.pending_mortgage = payload
    },
    VERIFIED_MORTGAGE(state,payload){
        state.verified_mortgage = payload;
    },
    DECLINED_MORTGAGE(state,payload){
        state.declined_mortgage = payload;
    },
    QUERIED_MORTGAGE(state,payload){
        state.queried_mortgage = payload;
    },
    APPROVED_MORTGAGE(state,payload){
        state.approved_mortgage = payload;
    }
}
const actions={
    dashboardInfoAction({commit}){
        api.dashboard().then((res)=>{
            let info = res.data.data;
            commit("DASHBOARD_INFO",info)
        })
    },
    mortgageDetailAction({commit},slug){
        mortgageapi.showMortgageDetail(slug).then((res)=>{
            console.log("from store", slug)
            const p = res.data.data.mortgage;
            const u = res.data.data.user_documents;
            commit("MORTGAGE_DETAILS",p);
            commit("USER_DOC",u);
        })
    },
    fetchMortgageByStatusAction({commit},status){
        return mortgageapi.fetchMortgageByStatus(status).then((res)=>{
            let m = res.data.data;
            switch (status) {
                case 'pending':
                    commit("PENDING_MORTGAGE",m);
                    break;
                case 'verify':
                    commit("VERIFIED_MORTGAGE",m);
                    break;
                case 'query':
                    commit("QUERIED_MORTGAGE",m);
                    break;
                case 'approve':
                    commit("APPROVED_MORTGAGE",m);
                    break;
                case 'decline':
                    commit("DECLINED_MORTGAGE",m);
                    break;
                default:
                    commit("PENDING_MORTGAGE",m);
                    break;
            }
        })
    },
    downloadApplicationAction({commit},slug){
        return mortgageapi.downloadApplication(slug).then((res)=>{

        })
    },
    showMortgagePageAction({commit},s){
        commit("SHOW_MORTGAGE_PAGE",s)
    },
    allDocumentAction({commit}){
        api.allDocument().then((res)=>{
            const p = res.data.data;
            commit("ALL_DOCUMENTS",p);
        })
    },
    allMortgageStatusAction({commit}){
        mortgageapi.allMortgageStatus().then((res)=>{
            const p = res.data.data;
            commit("ALL_MORTGAGE_STATUS",p);
        })
    },
    allMortgageAction({commit}){
        mortgageapi.allMortgageApplication().then((res)=>{
            const p = res.data.data;
            commit("ALL_MORTGAGE_LIST",p);
        })
    },
    allStaffMortgageAction({commit},staffid){
        mortgageapi.allStaffMortgageApplication(staffid).then((res)=>{
            const p = res.data.data;
            commit("ALL_STAFF_MORTGAGE_LIST",p);
        })
    },

    allRecentMortgage({commit}){
        mortgageapi.allrecentMortgage().then((res) => {
              const p = res.data.data;
              commit("RECENT_MORTGAGE_LIST", p);
          })
    },
    allStaffRecentMortgage({commit},lenderuser){
        mortgageapi.allstaffrecentMortgage(lenderuser).then((res) => {
              const p = res.data.data;
              commit("STAFF_RECENT_MORTGAGE_LIST", p);
          })
    },


    addStaffToMortgageAction({commit},data){
         let form = data.form
        let slug = data.slug
        return new Promise((resolve,reject)=>{
            api.attachStaffToMortgage(form,slug).then((res)=>{
            const p = res.data.data
            // const u = res.data.data.user_documents;
            // commit("MORTGAGE_DETAILS",p);
            // commit("USER_DOC",u);
            resolve(res);
        })
        })
       
    },
    approvedMortgageAction({commit},slug){
        return new Promise((resolve,reject)=>{
            mortgageapi.approvedMortgage(slug).then((res)=>{
                const p = res.data.data
                resolve(p)
                // commit("MORTGAGE_DETAILS",p);
            })
        })

    },
    declinedMortgageAction({commit},data){
        let form = data.form
        let slug = data.slug
        return new Promise((resolve,reject)=>{
            mortgageapi.declinedMortgage(form,slug).then((res)=>{
                const p = res.data.data
                commit("MORTGAGE_DETAILS",p);
                resolve(p)
            })
        })

    },
   requestForDocumentAction({commit},data){
       let form = data.form;
       let slug = data.slug;
       return new Promise((resolve,reject)=>{
        api.requestForDocument(form,slug).then((res)=>{
            const p = res.data.data
            resolve(p)
            commit("MORTGAGE_DETAILS",p);
        })
       })
       
    },
//    new action

    allPendingMortgageAction({commit}){
        return mortgageapi.allPendingMortgage().then((res)=>{
            let pending = res.data.data;
            commit("PENDING_MORTGAGE",pending)
        })
    },
    allVerifiedMortgageAction({commit}){},
    allDeclinedMortgageAction({commit}){},
    allQueriedMortgageAction({commit}){},
    allApprovedMortgageAction({commit}){}


}
const getters={}
const mortgage_module = {
    state,mutations,actions,getters
}
export default mortgage_module;