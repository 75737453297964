import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueHtmlToPaper from 'vue-html-to-paper';
import component from './components/component';
import filter from './plugins/filter';
import VueLitebox from 'vue-litebox'
import ApiService from './services/ApiService';
import {
  TokenService
} from './services/TokenService'

Vue.config.productionTip = false
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/atlantis.min.css';
import './assets/css/fonts.min.css';
const options = {
  name: 'MortgageApplication',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://app.financeplus.ng/css/print.css',
    'https://app.financeplus.ng/css/bootstrap.css',
    'https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.1.12/vuetify.min.css',

  ]
}
Vue.use(VueHtmlToPaper, options);
ApiService.init('https://app.financeplus.ng/api/');
// ApiService.init('http://financeplus.loc/api/');

if (TokenService.getToken()) {
  ApiService.setHeader()
}
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
    data: {
      bus: new Vue(),
     
  },
  provide: function () {
      Bus:this.bus
    }
}).$mount('#app')