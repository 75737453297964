// import Router from 'vue-router'
import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  TokenService
} from '../services/TokenService'
import EventBus from '../services/event'
import backend_routes from './backend_routes'
import Login from '../views/frontend/Login.vue'
import store from '@/store'


Vue.use(VueRouter)

let frontend_routes = [

  {
    path: '/login',
    name: 'login',
    meta: {
      layout: "frontend",
      public: true,
      onlylogout: true,
        authorize: []
    },
    component: Login
  },
  {
    path: '/regiser',
    name: 'register',
    meta: {
      layout: "frontend",
      public: true,
      onlylogout: true,
        authorize: []

    },
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/frontend/Register.vue')
  },
  {
    path: '/forgot',
    name: 'forgot',
    meta: {
      layout: "frontend",
      public: true,
      onlylogout: true,
        authorize: []
    },
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/frontend/Forgot.vue')
  },
  {
    path: '/',
    redirect: {
      name: 'login'

    }
  },
 
  {
    path: '*',
    name: 'page404',
    meta: {
      layout: "frontend",
      public: true,
      onlylogout: true,
        authorize: []
      // middleware: [ auth]
    },
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/frontend/Page404.vue')
  }
];
const routes = frontend_routes.concat(backend_routes)
// console.log(backend_routes)
const router = new VueRouter({
  mode: 'history',
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
});
router.beforeResolve((to, from, next) => {
  if (to.name) {
    // NProgress.start();
    EventBus.$emit("loading", true)
  }
  next();
});

router.afterEach((to, from) => {
  // NProgress.done();
  EventBus.$emit("loading", false)
});
router.beforeEach((to, from, next) => {
  const authorize = to.meta.authorize;
  const currentUserRole = store.state.authmodule.user.role;
  // const role_status = authorize.includes(currentUserRole);
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlylogout)
  let loggedIn = !!TokenService.getToken();
  if (!isPublic && !loggedIn && to.path !== '/login') {
      return next({
        path: '/login',
          query: {
            redirect: to.fullPath
          }
      });

  }
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/home')
    // console.log('role_status',role_status)
      // if (!role_status) {
      //     return next({
      //       path: '/not-authorize'
      //     })
      // }
      
    }
  next();
});

export default router