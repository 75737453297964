<template>
    <div >
      <component :is="layout"></component>
    </div>
</template>

<script>
const frontend = 'frontend'
export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed:{
    layout(){
      return (this.$route.meta.layout || frontend)+'-layout'
    }
  }
};
</script>
<style >
/* @import url('./assets/css/atlantis.css');
@import url('./assets/css/font.min.css'); */
.a_link{
    cursor: pointer;
    text-decoration: none ;
}
/* .router-link-active{
	opacity: 1;
    color: #0168fa;
    font-weight: 500;
}
.router-link-exact-active{
	opacity: 1;
    color: #0168fa;
    font-weight: 500;
} */
/* .theme--light {
    background: #0168fa !important;
    color: rgba(0, 0, 0, 0.87);
} */
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: rgba(0, 0, 0, 0.87) !important;
}

.mycontainer{
  width:100% !important;
}
/*.v-application {*/
/*    font-family: $ body-font-family, sans-serif !important;*/

/*.title {*/
/*/ / To pin point specific classes of some components font-family: $ title-font, sans-serif !important;*/
/*}*/

/*}*/
/* dialog overflows horizontally for application details */
.v-dialog.v-dialog--active.v-dialog--fullscreen {
  overflow-x: hidden !important;
}
</style>
