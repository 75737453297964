var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"mycontainer"},[_c('v-card',[_c('my-loader'),_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_vm._v(" All Applications")]),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.mortgagestatus,"item-text":"status","item-value":"status","label":"Filter By Status"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('div',{staticClass:"m-t-25 "},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.mortgages,"items-per-page":5,"search":_vm.search},scopedSlots:_vm._u([{key:"item.mortgage_status_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 text-white",attrs:{"small":"","color":_vm.showMortgageStatus(item.mortgage_status_name)}},[_vm._v(_vm._s(item.mortgage_status_name))])]}},{key:"item.firstname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")]}},{key:"item.loan_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.loan_amount))+" ")]}},{key:"item.total_annual_pay",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total_annual_pay))+" ")]}},{key:"item.loan_tenure",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.loan_tenure)+" years ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","medium":""},on:{"click":function($event){return _vm.showDetailPage(item.slug)}}},[_vm._v("mdi-eye-circle-outline ")]),(_vm.user.is_admin==1)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green","medium":""},on:{"click":function($event){return _vm.showAddUserDialog(item)}}},[_vm._v("mdi-account ")]):_vm._e()]}}])})],1)])],1),_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.addStaffDialog),callback:function ($$v) {_vm.addStaffDialog=$$v},expression:"addStaffDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary lighten-2 text-center",attrs:{"color":"#fff"}},[_vm._v(" Assign Staff to "+_vm._s(_vm.application.app_ref)+" "+_vm._s(_vm.application.lender_user_id)+" ")]),_c('v-form',{ref:"assign_form"},[_c('v-card-text',[_c('v-radio-group',{ref:"staff",attrs:{"name":"staff","column":"","rules":[_vm.rules.required],"error-messages":_vm.assignstaff_form_errorMessages},model:{value:(_vm.assignstaff_form.staff),callback:function ($$v) {_vm.$set(_vm.assignstaff_form, "staff", $$v)},expression:"assignstaff_form.staff"}},_vm._l((_vm.staff),function(s,i){return _c('v-radio',{key:i,attrs:{"checked":s.id==_vm.application.lender_user_id,"label":s.firstname,"value":s.id}})}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.addStaffDialog = false}}},[_vm._v(" Close ")]),_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","loading":_vm.apiloading,"large":"","disabled":_vm.apiloading,"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.assignStaffToMortgage(_vm.application.slug)}}},[_vm._v("Assign Staff ")])],1)],1)],1)],1)],1)],1),_c('success-card',{attrs:{"text":_vm.success_text}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }