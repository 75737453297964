import axios from 'axios'
import NProgress from "nprogress";
import {TokenService} from './TokenService'
import toast from './ToastService'
import Vue from 'vue'
import EventBus from './event'

axios.interceptors.request.use(config => {
  NProgress.start();
  EventBus.$emit("loading",true)
  return config;
});

// before a response is returned stop nprogress
axios.interceptors.response.use(
    function (response) {
		// NProgress.done();
		EventBus.$emit("loading",false)
    return response;
    },
    function(error) {
		// NProgress.done();
		EventBus.$emit("loading",false)
        // check for errorHandle config
        if( error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false ) {
            return Promise.reject(error);
        }

        if (error.response) {
            // console.log(error.response.data);
            try {
              toast.error(error.response.data.data);
            } catch (error) {
              toast.error('An error occured');
            }
        }
    }
)
const ApiService = {
			init(baseURL) {
				axios.defaults.baseURL = baseURL;
			},

			setHeader() {
				axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`
			},

			removeHeader() {
				axios.defaults.headers.common = {}
			},
			get(resource) {
				return axios.get(resource)
			},

			post(resource, data) {
				return axios.post(resource, data)
			},

			put(resource, data) {
				return axios.put(resource, data)
			},

			delete(resource) {
				return axios.delete(resource)
			},

			/**
			 * Perform a custom Axios request.
			 *
			 * data is an object containing the following properties:
			 *  - method
			 *  - url
			 *  - data ... request payload
			 *  - auth (optional)
			 *    - username
			 *    - password
			 **/
			customRequest(data) {
				return axios(data)
			}
}
export class ApiSource {
        // constructor(baseurl) {
        // 	this.baseurl = baseurl;
        // }
			// setHeader() {
			// 	axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`
			// }

			// removeHeader() {
			// 	axios.defaults.headers.common = {}
			// }
         async sendrequest(httpmethod, url, data) {
             return await axios.request({
               method: httpmethod,
               url: url,
               data: data
             });
        }

				//  authentication apis
         async login(authData) {
           return await this.sendrequest("post","login",authData );
         }
         async register(authData) {
           return await this.sendrequest("post","agent/register",authData);
		 }
		 async changepassword(authData) {
		 	return await this.sendrequest("post", "lender/change-password", authData);
		 }
		 async updateprofile(authData) {
		 	return await this.sendrequest("post", "lender/update-profile", authData);
		 }
		  async allDocument() {
		  	return await this.sendrequest("get", "all-docs");
		  }
		   async allMortgageStatus() {
		  	return await this.sendrequest("get", "all-mortgage-status");
		  }
		  async dashboard() {
		  	return await this.sendrequest("get", "lender/dashboard");
		  }




		   async allStaff() {
		   	return await this.sendrequest("get", "lender/all-staff");
		   }
		    async addStaff(data) {
		    	return await this.sendrequest("post", "lender/add-staff", data);
			}
			async updateStaff(data,slug) {
				return await this.sendrequest("post", "lender/update-staff/"+slug, data);
			}
			async deleteStaff( slug) {
			  	return await this.sendrequest("post", "lender/delete-staff/" + slug);
			  }
		}
export default ApiService
