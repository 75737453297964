import axios from 'axios'
import toast from './ToastService'
import Vue from 'vue'
import EventBus from './event'

axios.interceptors.request.use(config => {
  EventBus.$emit("loading",true)
  return config;
});

// before a response is returned stop nprogress
axios.interceptors.response.use(
    function (response) {
		EventBus.$emit("loading",false)
    return response;
    },
    function(error) {
		EventBus.$emit("loading",false)
        // check for errorHandle config
        // console.log('ERROR CONFIG', error.config)
        // if( error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false ) {
        //     return Promise.reject(error);
        // }

        if (error.response) {
            console.log(error.response.data);
            try {
              toast.error(error.response.data.data);
            } catch (error) {
              console.log(error.message);
              toast.error('An error occured');
            }
        } else {
          return Promise.reject(error);
        }
    }
)
const MortgageApiService = {
			init(baseURL) {
				axios.defaults.baseURL = baseURL;
			},

			setHeader() {
				axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`
			},

			removeHeader() {
				axios.defaults.headers.common = {}
			},
			get(resource) {
				return axios.get(resource)
			},

			post(resource, data) {
				return axios.post(resource, data)
			},

			put(resource, data) {
				return axios.put(resource, data)
			},

			delete(resource) {
				return axios.delete(resource)
			},

			/**
			 * Perform a custom Axios request.
			 *
			 * data is an object containing the following properties:
			 *  - method
			 *  - url
			 *  - data ... request payload
			 *  - auth (optional)
			 *    - username
			 *    - password
			 **/
			customRequest(data) {
				return axios(data)
			}
}
export class MortgageApiSource {
	// constructor(baseurl) {
	// 	this.baseurl = baseurl;
	//   }

	  async sendrequest(httpmethod, url, data) {
		  return await axios.request({
			method: httpmethod,
			url: url,
			data: data
		  });
	 }
		async allDocument(){
			return await this.sendrequest("get","all-docs");
		}
		async dashboard(){
			return await this.sendrequest("get","mortgage/dashboard");
		}
		async allrecentMortgage(){
				return await this.sendrequest("get","lender/all-recent-mortgage");
			}
		async allregularMortgage(){
			return await this.sendrequest("get","mortgage/all-regular-mortgage");
		}
		async allnhfMortgage(){
			return await this.sendrequest("get","mortgage/all-nhf-mortgage");
		}
		async showMortgageDetail(slug){
			return await this.sendrequest("get","lender/application-details/"+slug);
		}
		async downloadApplication(slug){
			return await this.sendrequest("get","lender/download/"+slug);
		}
		async approvedMortgage(slug){
			return await this.sendrequest("get","lender/approved-mortgage-application/"+slug);
		}
		async declinedMortgage(form,slug){
			return await this.sendrequest("post","lender/declined-mortgage-application/"+slug,form);
    }
    async verifyMortgage(slug) {
      return await this.sendrequest('get', 'lender/verify-mortgage-application/' + slug);
    }
    async queryMortgage(slug, payload) {
      return await this.sendrequest('post', 'lender/query-mortgage-application/' + slug, payload);
    }
		async requestForDocument(data,slug){
			return await this.sendrequest("post","mortgage/send-required-doc/"+slug, data);
		}
		async fetchMortgageByStatus(status){
			return await this.sendrequest("get","lender/application-status/"+status);
		}
	async allPendingMortgage(){
		return await this.sendrequest("get","lender/all-pending-applications");
	}
	async allVerifiedMortgage(){
		return await this.sendrequest("get","");
	}
	async allDeclinedMortgage(){
		return await this.sendrequest("get","");
	}
	async allQueriedMortgage(){
		return await this.sendrequest("get","");
	}
	async allApprovedMortgage(){
		return await this.sendrequest("get","");
	}
	async all(){
		return await this.sendrequest("get","");
	}
}
export default MortgageApiService
