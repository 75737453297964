var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"mycontainer"},[_c('v-card',[_c('my-loader'),_c('v-card-title',[_vm._v(" All Assigned Applications "),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('div',{staticClass:"m-t-25 "},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.mortgages,"items-per-page":5,"search":_vm.search},scopedSlots:_vm._u([{key:"item.mortgage_status_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 text-white",attrs:{"small":"","color":_vm.showMortgageStatus(item.mortgage_status_name)}},[_vm._v(_vm._s(item.mortgage_status_name))])]}},{key:"item.firstname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")]}},{key:"item.loan_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.loan_amount))+" ")]}},{key:"item.total_annual_pay",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total_annual_pay))+" ")]}},{key:"item.loan_tenure",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.loan_tenure)+" years ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.showDetailPage(item.slug)}}},[_vm._v("mdi-eye-circle-outline ")])]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }