<template>
    <div>
        <div class="row">
            <div class="col-md-3 col-xs-12">
                <v-card
                        class="mx-auto"
                        max-width="300"
                        tile
                >
                    <v-list disabled>
                        <v-subheader>CLIENT INFO</v-subheader>
                        <v-list-item-group v-model="item" color="primary">
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon v-text="mdi-account"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="User">User</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </div>
            <div class="col-md-9 col-xs-12"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SummaryCard",
        props:["item"]
    }
</script>

<style scoped>

</style>