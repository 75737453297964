<template>
    <v-app class="white">
    
        <v-app-bar app elevation="0">
    
            <div class="d-flex align-center">
                <!-- <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png" transition="scale-transition" width="40" /> -->
                  <v-img alt="FinancePlus" class="shrink mr-2"  contain  src="../../assets/logo.png"  />
    
                    <!-- <v-img alt="Newhomes" class="shrink mt-1 hidden-sm-and-down" contain min-width="100" src="../../assets/nhlogo2.png"  /> -->
    
                <!-- <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100" src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png" width="100" /> -->
    
            </div>
    
    
    
            <v-spacer></v-spacer>
    
    
    
            <!-- <v-btn href="https://github.com/vuetifyjs/vuetify/releases/latest" target="_blank" text>
    
                <span class="mr-2 hidden-sm-and-down">Latest Release</span>
    
                <v-icon>mdi-open-in-new</v-icon>
    
            </v-btn> -->
    
        </v-app-bar>
    
    
    
        <v-content style="background:#fff;color:#000">
    
            <router-view></router-view>
    
        </v-content>
            <v-footer>
                FinancePlus
    <v-spacer></v-spacer>
    <div>&copy; {{ new Date().getFullYear() }}</div>
  </v-footer>
    </v-app>
</template>
<script>
export default {
    
}
</script>
<style >

</style>